import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';

import * as StoreActions from './actions';
import { PermissionsService } from './store.service';

@Injectable()
export class PermissionsEffects {
	constructor(
		private actions$: Actions,
		private permissionsService: PermissionsService,
	) {}

	loadPermissions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StoreActions.loadPermissions),
			exhaustMap(() => {
				return this.permissionsService.load().pipe(
					map((permissions) =>
						StoreActions.loadPermissionsSuccess({ permissions }),
					),
					catchError((error) =>
						of(
							StoreActions.loadPermissionsFailure({
								error: error.message,
							}),
						),
					),
				);
			}),
		),
	);
}
