import { Inject, Injectable } from '@angular/core';

import { Environments } from '@emrm/core/environments';
import { ApiService } from '@emrm/common/services/api';
import { PermissionApp } from '@emrm/permissions/types';

@Injectable()
export class PermissionsService {
	constructor(
		@Inject('ENVIRONMENTS') private readonly environments: Environments,
		private apiService: ApiService,
	) {}

	load() {
		return this.apiService.get$<PermissionApp[]>(
			`${this.environments.apiUrl}/permissions`,
		);
	}
}
