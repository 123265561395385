import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { environments } from '@emrm/core/environments';

import { PermissionsEffects } from './effects';
import { reducers } from './reducers';
import { FEATURE_KEY } from './state';
import { PermissionsService } from './store.service';

@NgModule({
	imports: [
		StoreModule.forFeature(FEATURE_KEY, reducers),
		EffectsModule.forFeature([PermissionsEffects]),
	],
	providers: [
		{
			provide: 'ENVIRONMENTS',
			useValue: environments,
		},
		PermissionsService,
	],
})
export class PermissionsStoreModule {}
